.app_title {
  color: #f0f8ff;
  align-items: center;
  justify-content: center;
  font-size: calc(14px + 2vmin);
  font-weight: bold;
  text-align: left;
  margin-left: 12px;
  margin-top: 8px;
}
.app_top_section {
  background-color: #232f3e;
  margin: 18px;
  border-radius: 4px;
}

.app_description {
  color: #f0f8ff;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
  text-align: left;
  padding: 14px;
}
.app_body {
  background-color: #002f5b;
}
/*--Custom Table for New & Edit Form-- START--*/
.table_form {
  display: table;
  width: 100%;
}

.table_form_row {
  display: table-row;
  text-align: left;
  padding-left: 2px;
}

.table_form_row_cell {
  display: table-cell;
  padding: 10px;
  border: 0px solid #999999;
  vertical-align: top;
}

.table_form_heading {
  display: table-header-group;
  background-color: #ddd;
  font-weight: bold;
}

.k-animation-container {
  z-index: 10010 !important;
}
.k-textarea > .k-input {
  white-space: normal;
  min-height: 60px;
  font-size: 12px !important;
  padding: 0px !important;
  min-height: 32px;
}

.k-textarea {
  margin-top: 10px !important;
  line-height: 16px !important;
}

.k-dropdown {
  /* font-size: 12px!important; */
}

/*--Custom Table for New & Edit Form-- END--*/

/* ---Custom Grid, Input, Button style ---START -- */
.div_addnew {
  width: 100%;
  margin: 10px;
  text-align: left;
  float: left;
}

.migrationLog_grid {
  margin: 18px;
}

.migrationLog_grid_export {
  margin: 0px;
  text-align: right;
  float: right;
  padding: 10px;
}

.migrationLog_grid_GridToolbar {
  width: 100%;
  margin: 0px;
  text-align: right;
  float: right;
  display: flex;
}
.k-window-title {
  line-height: 1 !important;
}
.k-button {
  padding: 6px 10px !important;
  box-shadow: none;
  cursor: pointer !important;
}
.k-button-primary,
.k-button.k-primary {
  border-color: #3f51b5;
  color: #ffffff;
  background-color: #3f51b5;
  background-image: none;
  cursor: pointer !important;
}

.k-button-primary:hover,
.k-button.k-primary:hover {
  border-color: #3f51b5;
  color: #ffffff;
  background-color: #5161be;
  background-image: none;
  cursor: pointer !important;
}

.k-button-primary:active,
.k-button.k-primary:active {
  border-color: #3f51b5;
  color: #ffffff;
  background-color: #5161be;
  background-image: none;
}
.k-tooltip-content {
  font-size: 14px !important;
}
.tooltipcontent > div {
  line-height: 24px;
}
/* ---Custom Grid, Input, Button style --- END--*/

.chk-visible {
  text-align: center;
  margin: 10px;
  word-break: break-all;
  min-width: 120px;
  height: 6px;
}

.k-form .k-form-fieldset {
  margin: 0px !important;
}

.div_section {
  border: #002f5b 1px solid;
}
.div_section > label {
  margin: 10px;
}
.div_section > span {
  margin: 6px;
}
.div_section > .k-textbox-container {
  width: 94% !important;
}
.k-button-primary,
.k-button.k-primary {
  background-color: #c50f3c !important;
  border-color: #c50f3c !important;
}
.k-grid {
  border-width: 6px !important;
}
.outlooklogo {
  margin-left: 4px;
  height: 18px;
  vertical-align: middle;
  cursor: pointer;
}
.teamslogo {
  margin-left: 2px;
  height: 18px;
  vertical-align: middle;
  cursor: pointer;
}

.k-radio-list {
  padding-top: 5px !important;
  padding-left: 5px !important;
  font-size: 16px;
}
