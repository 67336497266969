.App {
  text-align: center;
  border-style: none;
  /* font-family: 'Raleway', sans-serif; */

  /* font-family: 'Work Sans', sans-serif; */
  /* font-family: 'Rubik', sans-serif; */
  /* font-family: 'B612', sans-serif; */
  /* font-family: 'Archivo', sans-serif; */
}
#hdr {
  display: flex;
  background-color: rgb(0, 47, 91);
  align-items: center;
  justify-content: space-between;

  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.5em;
  position: fixed;
  width: 98%;
  z-index: 1;
  border-bottom: 2px solid white;
}
.tpainLogo {
  height: 3em;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: aliceblue; */
  /* color: steelblue; */
  /* background-color: navy; */
  background-color: rgb(0, 47, 91);
  color: aliceblue;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.headerSpace {
  height: 15vh;
  background-color: transparent;
}
.containerForm {
  width: 50%;
  height: 25%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  margin-top: 70px;
}
.containerGrid {
  width: 70%;
  height: 25%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding-bottom: 0.5em;
  margin-top: 2rem;
}
.myRequestsTitle {
  font-size: 1.5rem;
}
.headingTitle {
  margin-block-end: 0;
}
.headingSubtitle {
  margin-bottom: 1em;
  font-size: 1em;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.myRequestsScroller {
  margin-top: 1em;
  margin-left: -50px;
  position: relative;
  display: block;
  height: 50px; /*height should be double border*/
}
.myRequestsScroller::before,
.myRequestsScroller::after {
  position: absolute;
  display: block;
  content: '';
  border: 25px solid transparent; /*adjust size*/
}
/*Change the four instances of 'top' below to rotate (top/right/bottom/left)*/
.myRequestsScroller::before {
  top: 0;
  border-top-color: #fff; /*Chevron Color*/
}
.myRequestsScroller::after {
  top: -10px; /*adjust thickness*/
  border-top-color: rgb(0, 47, 91); /*Match background colour*/
}

.myReqsButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.6em;
}

nav {
  padding-top: 120px;
}

.logo {
  margin-top: 0px;
  margin-left: 10px;
  display: block;
  padding: 10px;
}

.logo_div {
  background-color: #232f3e;
}

.hiddenField {
  visibility: hidden;
}

.div_section {
  padding: 1rem;
}
