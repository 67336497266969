.k-window {
  height: auto;
  width: max(70%, 480px) !important;
  overflow: auto;
  /* background-color:coral!important; */
}

.tileContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.tile {
  width: 45%;
  background-color: rgb(247 247 247);
  border-radius: 5px;
  border: 2px solid gray;
  padding: 2%;
  margin: 1%;
}

.k-form-field {
  height: 4rem;
}

.k-label {
  font-size: 16px !important;
  line-height: 10px;
  width: 100%;
  font-weight: bold;
}

.rowContainer {
  display: flex;
  flex-direction: column;
}

.textarea-comments {
  padding-left: 3.5%;
  width: 93.25%;
}
